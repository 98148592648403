import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { gsap } from 'gsap';
import { useForm } from "react-hook-form";
import Container from '../components/Container/Container'

const mobilePadding = css`
  @media (max-width: 768px) {
    padding: 50px 15px;
  }
`;

const SectionContact = styled.section`
  ${mobilePadding};
  margin-top: 40px;
  display: grid;
  place-items: center;
  min-height: calc(100vh - 327px);
  
  .thanks {
      display: grid;
      place-items: center;
      opacity: 0,
  }
  
  .contact-container {
      border: 1px solid var(--formWrapperBorderColor);
      padding: 10px 50px 60px;
      background: var(--formWrapperBg);
      border-radius: 15px;
      opacity: 0;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
            0 6.7px 5.3px rgba(0, 0, 0, 0.028),
            0 12.5px 10px rgba(0, 0, 0, 0.035);
  }
  
  p {
      color: var(--textLighter);
      text-align: center;
      margin-bottom: 50px;
  }
`;

const ContentWrapper = styled.div`
  grid-column-gap: 60px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledForm = styled.form`
  text-align: right;
  margin-bottom: 0;
  
  input, textarea {
    width: 100%;
    background: var(--formInputBg);
    border: var(--formInputBorder);
    color: var(--textNormal);
    padding: 8px 10px;
    border-radius: 5px;
    
    &:focus {
      outline: none;
      box-shadow: 0 0 5px 2px #1589BA;
    }
  }
  
  ul {
    list-style: none;
    margin: 0;
    
    li {
      display: flex;
      flex-direction: column;
    }
  }
`;

const UserSumbmission = styled.div`
  display: block;
`;

const NameAndMail = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 10px;
  
  @media (max-width: 762px) {
   
    & > div:first-child {
      margin-bottom: 10px;
    }
  }
`;

const Message = styled.div`
  margin-top: 30px;
  
  @media (max-width: 762px) {
    margin-top: 20px;
  }  
`;

const InputWrapper = styled.div`
  border-radius: 15px;
  position: relative;
  
  label {
    position: absolute;
    top: 8px;
    left: 10px;
    transform-origin: left;
    transition: transform .3s ease-in-out;
    color: var(--formInputColor);
    cursor: text;
  }
  
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus + label,
  textarea:not(:placeholder-shown) ~ label {
    border: none;
    color: #868686;
    transform: translate(-9px, -31px);
    font-size: 60%;
  }
`;

const ContactPage = () => {

    const { register, handleSubmit } = useForm();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState("");
    const [formSent, setFormSent] = useState();


    function submitForm() {
        const functionPath = `/.netlify/functions/sendgrid`;
        const functionOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                name,
                email,
                msg,
            }),
        };

        fetch(functionPath, functionOptions)
            .then(response => console.log(response))
            .then(data=>{
                setFormSent('sent')
                return data;
            })
            .catch(err => {
                console.error('There was an ERROR!!!',err)
            });

        setEmail('');
        setMsg('');
        setFormSent('sending');

    }

    useEffect(() => {
        gsap.fromTo('.contact-container', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5});
        gsap.fromTo('.thanks', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5});
    }, [formSent]);

    const formFieldRef = useRef();

    // switch(formSent) {
    //     default:
            return (
                <SectionContact>
                    <Container>
                        <div className="contact-container">
                            <h1>Masz jakieś pytania?</h1>
                            <p>Niezależnie, czy chodzi o zlecenie, współprace czy też pomoc techniczną - zachęcam do kotaktu. </p>
                            <ContentWrapper>
                                <StyledForm
                                    name="contact" method="post"
                                    action="/success">
                                    <UserSumbmission>
                                        <NameAndMail>
                                            <InputWrapper>
                                                <input
                                                    type="text"
                                                    placeholder=" "
                                                    name="name"
                                                    id="name"
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                    ref={register({
                                                        required: true
                                                    }, formFieldRef)}
                                                />
                                                <label htmlFor="name">Imię</label>
                                            </InputWrapper>
                                            <InputWrapper>
                                                <input
                                                    type="email"
                                                    placeholder=" "
                                                    name="email"
                                                    id="email"
                                                    onChange={e => setEmail(e.target.value)}
                                                    value={email}
                                                    ref={
                                                        register({
                                                            required: true,
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: `Wrong email format`
                                                            }}, formFieldRef)}
                                                />
                                                <label htmlFor="email">Adres e-mail</label>
                                            </InputWrapper>
                                        </NameAndMail>
                                        <Message>
                                            <InputWrapper>
                                                <textarea
                                                    name="message"
                                                    placeholder=" "
                                                    id="message"
                                                    rows="5"
                                                    onChange={e => setMsg(e.target.value)}
                                                    value={msg}
                                                    ref={register({
                                                        required: true
                                                    }, formFieldRef)}
                                                />
                                                <label htmlFor="message">Wiadomość</label>
                                            </InputWrapper>
                                        </Message>
                                    </UserSumbmission>
                                    <button onClick={handleSubmit(submitForm)}>
                                        Send
                                    </button>
                                </StyledForm>
                            </ContentWrapper>
                        </div>
                    </Container>
                </SectionContact>
            );

    //     case 'sending':
    //         return (
    //             <SectionContact>
    //                 <div className="thanks">
    //                     Wysyłanie...
    //                 </div>
    //             </SectionContact>
    //         )
    //
    //     case 'sent':
    //         return (
    //             <SectionContact>
    //                 <div className="thanks">
    //                     <h1>Dzięki, {name}. Wiadomość wysłana pomyślnie 🙂</h1>
    //                     <button onClick={() => {
    //                         setFormSent(false);
    //                         setName('');
    //                     }}>
    //                         Wróć
    //                     </button>
    //                 </div>
    //             </SectionContact>
    //         )
    // }
}

export default ContactPage;

